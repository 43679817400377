import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  Dialog,
  Container,
  Collapse,
  Box,
  darken,
  fade,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useTheme,
  Button,
} from "@material-ui/core"
import { Link } from "gatsby-theme-material-ui"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import {
  ArrowDropDown,
  ArrowDropUp,
  Close,
  MenuSharp as MenuIcon,
} from "@material-ui/icons"
import SocialIcons from "./SocialIcons"
import { ContactForm } from "../components/ContactFormDescription"

const Menu = styled.ul`
  ${({ theme }) => `
    list-style:none;
    margin:0;
    height:100%;
    margin-left:0;
    padding-left:0;
    display:flex;
    & & { 
        // submenu
        display:none;
        position:absolute;
        background:white;
        left:50%;
        top:80%;
        padding: ${theme.spacing(0.25, 0.5)};
        box-shadow:0 0 10px ${fade(theme.palette.primary.main, 0.2)};
        font-size: .8rem;
        min-width:100px;
        transform:translateX(-50%);
        height:auto;
        a {
            color: ${theme.palette.text.primary};
            padding:0;
        }
        li {
            height:auto;
        }
        a:hover {
            color: ${darken(theme.palette.text.primary, 0.3)}
        }
    }`}
`

const MenuItem = styled.li`
  ${({ theme }) => `
    position:relative;
    height:100%;
    white-space:nowrap;
    > a {
        display:flex;
        align-items:center;
        height:100%;
        padding: 1rem;
    }
    cursor:pointer;
    &:first-child {
        padding-left:0;
    }
    &:last-child {
        padding-right:0;
    }
    &:hover {
        > a {
            color: ${theme.palette.primary.main};
        }
        ${Menu} {
            display:block;
        }
    }
`}
`

const HeaderWrapper = styled(Box)`
  position: sticky;
  top: 0;
  background-color: white;
  transition: 0.3s box-shadow;
  z-index: 1;
  &.stuck {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  }
`

const Header = () => {
  const theme = useTheme()
  const [contactFormVisible, setContactFormVisible] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [subMenuOpen, setSubMenuOpen] = useState({})
  const [anchor, setAnchor] = useState(() => {
    if (
      typeof window !== `undefined` &&
      window.width < theme.breakpoints.values[`sm`]
    ) {
      return `top`
    }
    return `right`
  })
  const handleScroll = (e) => {
    if (e.currentTarget.scrollY > 0) {
      headerWrapperRef.current.classList.add(`stuck`)
      return
    }
    headerWrapperRef.current.classList.remove(`stuck`)
  }
  const handleResize = useCallback(
    (e) => {
      if (e.currentTarget.innerWidth < theme.breakpoints.values[`sm`]) {
        setAnchor(`top`)
        return
      }
      setAnchor(`right`)
    },
    [theme.breakpoints]
  )

  const openContactForm = () => {
    window.dataLayer?.push({
      event: `contact_form_open`,
      page: `${window.location.href}`,
      element: `header`,
    })
    setContactFormVisible(true)
    setMenuOpen(false)
  }
  const headerWrapperRef = useRef()
  const {
    site: {
      siteMetadata: { title },
    },
    contentfulNavigation: { items },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        contentfulNavigation(contentful_id: { eq: "Ns1XCsyruyCEy4EUgXW9r" }) {
          items {
            title
            url
            page {
              path
            }
            contentfulchildren {
              title
              url
              attributes {
                target
              }
              page {
                path
              }
            }
          }
        }
      }
    `
  )
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener(`resize`, handleResize)
      if (headerWrapperRef.current) {
        window.addEventListener(`scroll`, handleScroll)
      }
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener(`scroll`, handleScroll)
        window.removeEventListener(`resize`, handleResize)
      }
    }
  }, [handleResize, headerWrapperRef, theme.breakpoints.values])
  return (
    <>
      <Dialog
        open={contactFormVisible}
        onClose={() => setContactFormVisible(false)}
        maxWidth="md"
      >
        <Container>
          <Box p={1}>
            <ContactForm heading={`Contact Andrew Rhatigan`} />
          </Box>
        </Container>
      </Dialog>
      <HeaderWrapper ref={headerWrapperRef}>
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box py={1}>
              <Link to="/" style={{ lineHeight: 0 }}>
                <img src={`/images/header-logo.svg`} alt={title} />
              </Link>
            </Box>
            <Hidden smUp>
              <MenuIcon
                fontSize="large"
                color="primary"
                onClick={() => setMenuOpen(true)}
              />
            </Hidden>
            <Drawer
              id="mobile-nav"
              width="300px"
              elevation={1}
              transitionDuration={300}
              PaperProps={{
                style: { padding: theme.spacing(1, 2), minWidth: 340 },
              }}
              ModalProps={{ style: { height: `100%` } }}
              anchor={anchor}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Link to="/">
                  <Box>
                    <img src={`/images/header-logo.svg`} alt={title} />
                  </Box>
                </Link>
                <Close
                  color="primary"
                  fontSize="large"
                  onClick={() => setMenuOpen(false)}
                />
              </Box>
              <List style={{ padding: theme.spacing(1, 1) }}>
                {items.map(
                  (
                    { title, url, page, contentfulchildren: children },
                    index
                  ) => (
                    <Fragment key={`navitem-${index}`}>
                      <ListItem
                        disableGutters
                        style={{ flexDirection: `column` }}
                        divider
                        button
                        onClick={() =>
                          setSubMenuOpen({
                            ...subMenuOpen,
                            [index]: !subMenuOpen[index],
                          })
                        }
                      >
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {page?.path || url ? (
                            <Link to={page?.path || url} color="textPrimary">
                              <ListItemText primary={title} />
                            </Link>
                          ) : (
                            <ListItemText primary={title} />
                          )}
                          {children &&
                            (subMenuOpen[index] ? (
                              <ArrowDropUp color="secondary" />
                            ) : (
                              <ArrowDropDown color="secondary" />
                            ))}
                        </Box>
                        {children && (
                          <Collapse in={subMenuOpen[index]}>
                            <List>
                              {children.map(
                                ({ title, url, page }, childIndex) => (
                                  <ListItem
                                    key={`navitem-${index}-${childIndex}`}
                                    style={{
                                      justifyContent: `flex-end`,
                                      fontSize: `.9rem`,
                                      padding: theme.spacing(0.1, 1),
                                    }}
                                  >
                                    <Link
                                      color="textPrimary"
                                      to={page?.path || url}
                                    >
                                      {title}
                                    </Link>
                                  </ListItem>
                                )
                              )}
                            </List>
                          </Collapse>
                        )}
                      </ListItem>
                    </Fragment>
                  )
                )}
              </List>
              <Box my={2} display="flex" justifyContent="center">
                <Button
                  color="secondary"
                  id="open-contact-form-button"
                  onClick={openContactForm}
                >
                  Contact Andrew
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                style={{ fill: theme.palette.primary.main }}
              >
                <SocialIcons />
              </Box>
            </Drawer>
            <Hidden xsDown>
              <Box display="flex" id="desktop-nav">
                <Box component="nav" height={1}>
                  <Menu style={{ height: `100%` }}>
                    {items.map(
                      (
                        { title, url, page, contentfulchildren: children },
                        index
                      ) => (
                        <MenuItem key={`navitem-${index}`}>
                          <Link color="textPrimary" to={page?.path || url}>
                            {title}
                            {children && <ArrowDropDown fontSize="small" />}
                          </Link>
                          {children && (
                            <Menu>
                              {children.map(
                                ({ title, url, page }, childIndex) => (
                                  <MenuItem
                                    key={`navitem-${index}-${childIndex}`}
                                  >
                                    <Link to={page?.path || url}>{title}</Link>
                                  </MenuItem>
                                )
                              )}
                            </Menu>
                          )}
                        </MenuItem>
                      )
                    )}
                    <Box display="flex" alignItems="center" pl={1}>
                      <IconButton
                        size="small"
                        onClick={() => setMenuOpen(true)}
                      >
                        <MenuIcon fontSize="large" color="primary" />
                      </IconButton>
                    </Box>
                  </Menu>
                </Box>
              </Box>
            </Hidden>
          </Box>
        </Container>
      </HeaderWrapper>
    </>
  )
}

export default Header
