import { createMuiTheme, darken } from "@material-ui/core/styles"
import { createGlobalStyle } from "styled-components"

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 1024,
      lg: 1280,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      dark: `#011d4d`,
      main: `#0077bb`,
      light: `#eef7fe`,
      alt: `#005193`,
    },
    secondary: {
      light: `#eaeef1`,
      main: `#d6dfe4`,
    },
    text: {
      primary: `#6c7981`,
      secondary: `#011d4d`,
    },
    background: {
      default: `#ffffff`,
    },
  },
  spacing: (factor) => `${1 * factor}rem`,
})

const globalStyles = {
  h1: {
    fontSize: `2rem`,
    textTransform: `uppercase`,
    letterSpacing: `.1em`,
    lineHeight: `1.7em`,
    fontWeight: `bold`,
    marginTop: 0,
    color: theme.palette.primary.dark,
  },
  h2: {
    fontSize: `1.7rem`,
    textTransform: `uppercase`,
    fontWeight: `bold`,
    marginBottom: `1rem`,
    color: theme.palette.primary.dark,
    letterSpacing: `.1em`,
    lineHeight: `1.7em`,
    marginTop: 0,
  },
  h3: {
    fontSize: `1.3rem`,
    textTransform: `uppercase`,
    fontWeight: `bold`,
    color: theme.palette.primary.dark,
    letterSpacing: `.1em`,
    lineHeight: `1.7em`,
    marginTop: 0,
  },
  h4: {
    fontSize: `1.1rem`,
    color: theme.palette.primary.dark,
    lineHeight: `1.4em`,
    marginTop: 0,
    fontWeight: `600`,
  },
  h5: {
    fontSize: `.95rem`,
    textTransform: `uppercase`,
    fontWeight: `bold`,
    color: theme.palette.primary.dark,
    letterSpacing: `.05em`,
    lineHeight: `1.7em`,
    marginTop: 0,
  },
  button: {
    textTransform: `uppercase`,
    position: `relative`,
    fontSize: `1.05rem`,
    letterSpacing: `.1em`,
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: `.95rem`,
  },
  a: {
    color: theme.palette.primary.main,
    textDecoration: `none`,
  },
}

export default createMuiTheme({
  ...theme,
  typography: {
    fontFamily: `Montserrat, serif`,
    fontWeightRegular: `500`,
    fontSize: 16,
    body2: {
      lineHeight: `1.7em`,
    },
    body1: {
      lineHeight: `1.7em`,
    },
    ...globalStyles,
  },
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle1: `span`,
      },
    },
    MuiGrid: {
      spacing: 2,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiLink: {
      underline: `none`,
    },
    MuiTextField: {
      fullWidth: true,
      variant: `outlined`,
      rows: 4,
    },
    MuiFormControl: {
      fullWidth: true,
      variant: `outlined`,
    },
    MuiSelect: {
      variant: `outlined`,
      MenuProps: {
        anchorOrigin: {
          horizontal: `center`,
          vertical: `top`,
        },
        transformOrigin: {
          horizontal: `center`,
          vertical: `top`,
        },
        TransitionProps: {
          enter: false,
          exit: false,
        },
      },
    },
    MuiTabs: {
      textColor: `primary`,
    },
  },
  overrides: {
    MuiChip: {
      root: {
        fontSize: `14px`,
        fontWeight: 700,
      },
    },
    MuiSlider: {
      root: {
        color: theme.palette.secondary.main,
        padding: `20px 0`,
        paddingTop: 13,
      },
      rail: {
        height: 3,
      },
      track: {
        height: 3,
        backgroundColor: theme.palette.primary.dark,
      },
      thumb: {
        width: 24,
        height: 24,
        marginLeft: -12,
        marginTop: -10,
        color: theme.palette.primary.main,
      },
    },
    MuiTabs: {
      root: {
        minHeight: 0,
      },
      scrollable: {
        width: 0,
      },
      indicator: {
        backgroundColor: theme.palette.secondary.main,
        height: 3,
      },
    },
    MuiTab: {
      root: {
        fontSize: `1rem`,
        minHeight: 0,
        "@media (min-width: 680px)": {
          minWidth: 0,
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        ...globalStyles,
      },
    },
    MuiInputLabel: {
      root: {
        marginBottom: theme.spacing(1),
      },
    },
    MuiButtonGroup: {
      root: {
        "& .MuiFormControl-root:not(:last-child) .MuiOutlinedInput-root": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        "& .MuiFormControl-root:not(:first-child) .MuiOutlinedInput-root": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
    },
    MuiFab: {
      root: {
        "&, &:hover, &:focus": {
          color: `white`,
        },
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: darken(theme.palette.primary.main, 0.2),
        },
        "&:active": {
          boxShadow: theme.shadows[4],
        },
        boxShadow: theme.shadows[2],
        letterSpacing: `normal`,
        "& .MuiSvgIcon-root": {
          marginRight: `.5rem`,
        },
        sizeSmall: {
          padding: `.5em 1em`,
        },
      },
      extended: {
        "&.MuiFab-sizeSmall": {
          padding: `.5em 1em`,
        },
      },
      sizeSmall: {
        padding: `.5em 1em`,
        fontSize: `.9rem`,
      },
    },
    MuiContainer: {
      root: {
        [theme.breakpoints.up(`sm`)]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    },
    MuiCollapse: {
      container: {
        width: `100%`,
      },
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: `transparent`,
        },
      },
    },
    MuiDrawer: {
      paperAnchorTop: {
        height: `100%`,
      },
    },
    MuiLink: {
      root: {
        "&:hover": {
          color: darken(theme.palette.primary.main, 0.2),
        },
      },
    },
    MuiIconButton: {
      root: {
        "&.Mui-disabled svg * ": {
          stroke: theme.palette.secondary.dark,
        },
      },
    },
    MuiButton: {
      root: {
        borderBottom: `3px solid ${theme.palette.secondary.main}`,
        [theme.breakpoints.down(`xs`)]: {
          borderBottomWidth: `5px`,
        },
        borderRadius: 0,
        minWidth: 200,
        maxWidth: 300,
        margin: `auto`,
        color: theme.palette.primary.main,
      },
      textSecondary: {
        color: theme.palette.primary.dark,
        "&:hover": {
          backgroundColor: `rgba(108, 121, 129, 0.04)`,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      fullWidth: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(0),
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: theme.palette.secondary.light,
        },
      },
      notchedOutline: {
        borderWidth: 2,
        borderColor: `${theme.palette.secondary.light}`,
      },
    },
  },
})

// additional styles with references

export const GlobalStyles = createGlobalStyle`
    html, body, #___gatsby, #gatsby-focus-wrapper {
        height:100%;
    }
    * {
        outline:none;
    }
    img {
        max-width:100%;
    }
    p {
        margin-top:0;
    }
    h2 {
        
    }
    ol, ul {
        margin:0;
        padding:0;
        list-style-position:inside
    }
    button {
        &, &:hover a {
            text-decoration:none !important;
        }
        a {
            color:inherit !important;
        }
    }
    blockquote {
        font-style:italic;
    }

`
