import {
  Button,
  Container,
  Box,
  Typography,
  Select,
  MenuItem,
  Hidden,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import { Alert } from "@material-ui/lab";
import { fade, useTheme } from "@material-ui/core/styles";
import { IMaskMixin } from "react-imask";
import { useState } from "react";
import axios from "axios";
import * as yup from "yup";
import "yup-phone";
import { Fragment } from "react";

export const MaskedInput = IMaskMixin(({ inputRef, onChange, ...other }) => {
  return <TextField {...other} inputRef={inputRef} />;
});

export const submitContactForm = (values) => {
  window.dataLayer?.push({
    event: `contact_form_submit`,
    page: `${window.location.href}`,
  });
  return axios.post(`/.netlify/functions/send-mail`, values);
};

export const validationSchema = yup.object({
  name: yup.string().required(`Name is required`),
  phone: yup
    .string()
    .phone(353, undefined, `Please enter a valid phone number`),
  email: yup
    .string()
    .email(`Please enter a valid email address`)
    .required(`Email is required`),
});

const FormSection = ({
  heading = `Let's Get In Touch`,
  description,
  defaultValue = `buy`,
}) => {
  const hasDescription = !!description;
  const parsedDescription =
    hasDescription && description.childMarkdownRemark.html;
  const theme = useTheme();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleSubmit = async (values) => {
    setIsSubmitted(false);
    try {
      await submitContactForm(formik.values);
      setIsSubmitted(true);
      formik.resetForm();
    } catch (err) {
      console.error(err);
      formik.setStatus(`error`);
    }
  };
  const formik = useFormik({
    initialValues: {
      type: defaultValue,
      email: ``,
      name: ``,
      message: ``,
      phone: ``,
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <>
      <Box bgcolor="primary.light" py={2}>
        <Container maxWidth="lg">
          <Box
            bgcolor="white"
            padding={3}
            px={{ xs: 2 }}
            boxShadow={`0px 0px ${theme.spacing(1)} ${fade(
              theme.palette.primary.main,
              0.2
            )};`}
          >
            {formik.status === `error` && (
              <Box mb={1}>
                <Alert
                  severity={`error`}
                  onClose={() => formik.setStatus(undefined)}
                >
                  There was an error with the submission request
                </Alert>
              </Box>
            )}
            {!formik.isValid && (
              <Box mb={1}>
                <Alert severity={`error`}>
                  {Object.values(formik.errors).map((e, i) => (
                    <Fragment key={`error-${i}`}>
                      {e}
                      <br />
                    </Fragment>
                  ))}
                </Alert>
              </Box>
            )}
            {isSubmitted && (
              <Box mb={1}>
                <Alert
                  severity={`success`}
                  onClose={() => setIsSubmitted(false)}
                >
                  Form submitted
                </Alert>
              </Box>
            )}
            <Box
              display="flex"
              flexDirection={{ xs: `column`, sm: `row` }}
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <Box
                order={{ sm: 3 }}
                flex={{ sm: 1, md: `none` }}
                width={{ sm: `auto`, md: 350 }}
                justifyContent="center"
                display="flex"
                flexDirection="column"
              >
                <Typography variant={`h2`} align="center">
                  {heading}
                </Typography>
                {hasDescription && (
                  <div
                    dangerouslySetInnerHTML={{ __html: parsedDescription }}
                  />
                )}
                {!hasDescription && (
                  <>
                    <Box mb={1} width="100%">
                      <Select
                        disabled={formik.isSubmitting}
                        name="type"
                        onChange={formik.handleChange}
                        fullWidth
                        value={formik.values.type}
                      >
                        <MenuItem value="buy">Buy</MenuItem>
                        <MenuItem value="rent">Rent</MenuItem>
                        <MenuItem value="sell">Sell</MenuItem>
                      </Select>
                    </Box>
                    <Hidden xsDown>
                      <Box textAlign="center">
                        <Button
                          type="submit"
                          disabled={formik.isSubmitting}
                          id="contact-form-submit-button"
                        >
                          {formik.isSubmitting ? `Submitting` : `Submit`}
                        </Button>
                      </Box>
                    </Hidden>
                  </>
                )}
              </Box>
              <Hidden xsDown>
                <Box
                  mx={2}
                  order={2}
                  minHeight={1}
                  width={`1px`}
                  bgcolor={`secondary.main`}
                />
              </Hidden>
              <Box order={{ sm: 1 }} flex={1}>
                <TextField
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={(e) => formik.validateField(`name`)}
                  name="name"
                  placeholder="Name"
                  disabled={formik.isSubmitting}
                />
                <TextField
                  onChange={formik.handleChange}
                  onBlur={(e) => formik.validateField(`email`)}
                  value={formik.values.email}
                  name="email"
                  placeholder="Email"
                  disabled={formik.isSubmitting}
                />
                <TextField
                  onChange={formik.handleChange}
                  onBlur={(e) => formik.validateField(`phone`)}
                  value={formik.values.phone}
                  name="phone"
                  placeholder="Phone Number"
                  disabled={formik.isSubmitting}
                />
                <TextField
                  multiline
                  value={formik.values.message}
                  name="message"
                  placeholder="Message (Optional)"
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                />
                <Box display="flex" justifyContent="center">
                  <Hidden smUp>
                    <Button
                      type="submit"
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Submit
                    </Button>
                  </Hidden>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FormSection;
