import { Box, Container, Typography, useTheme } from "@material-ui/core"
import styled from "styled-components"

const Wrapper = styled(Box)`
  &::after {
    content: "";
    background: linear-gradient(to right, #0077bb 0%, #005193 100%);
    height: 5px;
    transform: translateY(100%);
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
  }
`

const HeroSection = ({ title, backgroundFit = false, ...props }) => {
  const imageUrl = props.backgroundImage
  const theme = useTheme()
  return (
    <Wrapper
      css={{
        backgroundSize: backgroundFit ? `contain` : `cover`,
        backgroundPosition: `center center `,
        backgroundRepeat: `no-repeat`,
        backgroundColor: theme.palette.primary.dark,
        backgroundImage: imageUrl ? `url(${imageUrl})}` : `none`,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      minHeight={400}
    >
      <Container>
        <Typography
          style={{ color: `white`, fontSize: `1.5rem` }}
          align={`center`}
          variant="h1"
        >
          {title}
        </Typography>
      </Container>
    </Wrapper>
  )
}

export default HeroSection
