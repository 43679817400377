import { Box, IconButton } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-theme-material-ui"
import styled from "styled-components"
import {
  LinkedIn,
  Facebook,
  Twitter,
  Instagram,
  YouTube,
} from "@material-ui/icons"

const SocialIcon = styled(IconButton)`
  &,
  &:hover,
  &:active,
  &:visited,
  & * {
    color: inherit;
    fill: inherit !important;
  }
`

const SocialIcons = () => {
  const {
    site: {
      siteMetadata: { linkedin, facebook, twitter, instagram, youtube },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            linkedin
            facebook
            twitter
            youtube
            instagram
          }
        }
      }
    `
  )
  return (
    <Box component="span">
      {linkedin && (
        <Link target="_blank" to={linkedin}>
          <SocialIcon>
            <LinkedIn />
          </SocialIcon>
        </Link>
      )}
      {facebook && (
        <Link target="_blank" to={facebook}>
          <SocialIcon>
            <Facebook />
          </SocialIcon>
        </Link>
      )}
      {twitter && (
        <Link target="_blank" to={twitter}>
          <SocialIcon>
            <Twitter />
          </SocialIcon>
        </Link>
      )}
      {youtube && (
        <Link target="_blank" to={youtube}>
          <SocialIcon>
            <YouTube />
          </SocialIcon>
        </Link>
      )}
      {instagram && (
        <Link target="_blank" to={instagram}>
          <SocialIcon>
            <Instagram />
          </SocialIcon>
        </Link>
      )}
    </Box>
  )
}

export default SocialIcons
