import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { css } from "styled-components";

const IframeContainerStyle = css`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;
`;

const IframeStyle = css`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export default (document, options) => {
  return documentToHtmlString(document, {
    ...options,
    renderNode: {
      "list-item": (node) => {
        return `<li>${node.content[0].content[0].value}</li>`;
      },
      paragraph: (node, next) => {
        if (node.content[0].value.includes(`[GoogleReviews]`)) {
          return `<div class="elfsight-app-87488ee2-8994-4436-89b9-634be00f8643"></div>`;
        }
        return `<p>` + next(node.content) + `</p>`;
      },
      hyperlink: (node, next) => {
        if (node.data.uri.includes(`youtube.com/embed`)) {
          return `<span style="${IframeContainerStyle}"><iframe
          style="${IframeStyle}"
                title="Unique Title 002"
                src="${node.data.uri}"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
              ></iframe></span>`;
        } else if (node.data.uri.includes(`calendly.com`)) {
          return `<a class="MuiFab-extended MuiButton-root MuiButtonBase-root MuiButton-containedPrimary" href="${node.data.uri}">${node.content?.[0].value}</a>`;
        }
        return `<a href="${node.data.uri}">${next(node.content)}</a>`;
      },
    },
  });
};
