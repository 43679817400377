import { Box, Container } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import SocialIcons from "./SocialIcons"

const Bullet = styled(`span`)`
  ${(theme) => `
    color: rgba(255,255,255,.4)
`}
`

const FooterWrapper = styled(Box)`
  ${({ theme }) => `
        a {
            color: white;
        }
        &::before {
            content: '';
            background: linear-gradient(to right, #0077bb 0%,#005193 100%);
            height: 15px;
            top:0;
            left:0;
            width:100%;
            position:absolute;
            transform:translateY(-100%)
        }
    `}
`

const Footer = () => {
  const {
    site: {
      siteMetadata: { company },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            company
          }
        }
      }
    `
  )
  return (
    <FooterWrapper
      component="footer"
      bgcolor="primary.dark"
      color="white"
      fontSize={`.8rem`}
      py={0.8}
      mt={`15px`}
      position="relative"
      style={{ fill: `white` }}
    >
      <Container>
        <Box
          display="flex"
          flexDirection={{ xs: `column`, sm: `row` }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box order={{ xs: 2, sm: 1 }}>
            &copy;{new Date().getFullYear()} {company}. All Rights Reserved.
          </Box>
          <Box order={{ xs: 1, sm: 2 }}>
            <SocialIcons />
          </Box>
        </Box>
        <Box textAlign={{ xs: `center`, sm: `left` }}>
          Office: <a href="tel:012118848">01 2118848</a> <Bullet>&bull;</Bullet>
          {` `}
          Mobile: <a href="tel:0864097485">086 4097485</a>
          {` `}
          <Bullet>&bull;</Bullet>
          {` `}
          andrew@rre.ie{` `}
          <Bullet>&bull;</Bullet> PSRA LICENCE: 004287
          <br />
        </Box>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
