import { Helmet } from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import Footer from "./Footer";
import Header from "./Header";
import theme, { GlobalStyles } from "../config/theme";
import { graphql, useStaticQuery } from "gatsby";
import HeroSection from "./HeroSection";
import { Box, Container } from "@material-ui/core";
import documentToHtmlString from "../util/documentToHtmlString";
import { lowerCase } from "lodash";

const SiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const SiteContent = styled.main`
  flex: 1;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Layout = ({
  title,
  backgroundImage,
  description,
  children,
  backgroundFit = false,
  metaDescription = ``,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        homePage: contentfulPage(title: { eq: "Home" }) {
          metaDescription {
            body: metaDescription
          }
        }
      }
    `
  );

  return (
    <ThemeTopLayout theme={theme}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>
            {title && lowerCase(title) !== `home` ? `${title} - ` : ``}
            {data.site.siteMetadata.title} - Dublin Real Estate
          </title>
          <meta
            name="description"
            content={metaDescription || data.homePage.metaDescription.body}
          />
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "http://www.rre.ie",
              "logo": "http://www.rre.ie/images/header-logo.svg"
            }`}
          </script>
        </Helmet>
        <GlobalStyles />
        <SiteWrapper>
          <Header />
          <SiteContent>
            {backgroundImage && (
              <HeroSection
                backgroundImage={backgroundImage}
                backgroundFit={backgroundFit}
                title={title}
              />
            )}
            {description && (
              <Container maxWidth="sm">
                <Box py={2} component="div">
                  {description.childMarkdownRemark && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description.childMarkdownRemark.html,
                      }}
                    />
                  )}
                  {description.raw && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: documentToHtmlString(
                          JSON.parse(description.raw)
                        ),
                      }}
                    />
                  )}
                </Box>
              </Container>
            )}
            {children}
          </SiteContent>
          <Footer />
        </SiteWrapper>
      </ThemeProvider>
    </ThemeTopLayout>
  );
};

export default Layout;
