import {
  Button,
  Container,
  Box,
  Typography,
  TextField,
  Fab,
} from "@material-ui/core";
import { Call } from "@material-ui/icons";
import { useState } from "react";
import { submitContactForm, validationSchema } from "./ContactForm";
import { useFormik } from "formik";
import { Alert } from "@material-ui/lab";
import { Fragment } from "react";

export const ContactForm = ({ heading, defaultValue }) => {
  const handleSubmit = async (values) => {
    setIsSubmitted(false);
    try {
      await submitContactForm(values);
      setIsSubmitted(true);
      formik.resetForm();
    } catch (err) {
      console.error(err);
      formik.setStatus(`error`);
    }
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: ``,
      name: ``,
      message: ``,
      phone: ``,
      type: defaultValue,
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  const openCalendly = () => {
    window.dataLayer?.push({
      event: `calendly_link_click`,
      page: ` ${window.location.href}`,
    });
    window.open(`https://calendly.com/rhatigan/15min`);
  };
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Box
        display="flex"
        flexDirection={{ xs: `column`, sm: `row` }}
        justifyContent={{ sm: `space-between` }}
        alignItems={{ sm: `center` }}
        mb={1}
      >
        <Box flex={1}>
          <Typography variant={`h2`} style={{ marginBottom: 0 }}>
            {heading}
          </Typography>
        </Box>
        <Box alignSelf={`flex-start`}>
          <Fab
            variant="extended"
            size="small"
            id="open-calendly-button"
            onClick={openCalendly}
          >
            <Call fontSize="small" /> Book a call
          </Fab>
        </Box>
      </Box>
      {formik.status === `error` && (
        <Box mb={1}>
          <Alert severity={`error`} onClose={() => formik.setStatus(undefined)}>
            There was an error with the submission request
          </Alert>
        </Box>
      )}
      {!formik.isValid && (
        <Box mb={1}>
          <Alert severity={`error`}>
            {Object.values(formik.errors).map((e, i) => (
              <Fragment key={`error-${i}`}>
                {e}
                <br />
              </Fragment>
            ))}
          </Alert>
        </Box>
      )}
      {isSubmitted && (
        <Box mb={1}>
          <Alert severity={`success`} onClose={() => setIsSubmitted(false)}>
            Form submitted
          </Alert>
        </Box>
      )}
      <TextField
        onChange={formik.handleChange}
        onBlur={(e) => formik.validateField(`name`)}
        value={formik.values.name}
        name="name"
        placeholder="Name"
        disabled={formik.isSubmitting}
      />
      <TextField
        onChange={formik.handleChange}
        onBlur={(e) => formik.validateField(`email`)}
        value={formik.values.email}
        name="email"
        placeholder="Email"
        disabled={formik.isSubmitting}
      />
      <TextField
        onChange={formik.handleChange}
        onBlur={(e) => formik.validateField(`phone`)}
        value={formik.values.phone}
        name="phone"
        type="tel"
        placeholder="Phone Number"
        disabled={formik.isSubmitting}
      />
      <TextField
        multiline
        value={formik.values.message}
        onChange={formik.handleChange}
        name="message"
        placeholder="Message (Optional)"
      />
      <Box display="flex" justifyContent="center">
        <Button type="submit" disabled={formik.isSubmitting || !formik.isValid}>
          {formik.isSubmitting ? `Submitting` : `Submit`}
        </Button>
      </Box>
    </Box>
  );
};

const FormSection = ({
  heading,
  defaultValue = `rent`,
  description: {
    childMarkdownRemark: { html: description },
  },
}) => {
  return (
    <Container maxWidth="lg">
      <Box py={3}>
        <Box
          display="flex"
          flexDirection={{ xs: `column`, md: `row` }}
          justifyContent={{ md: `space-between` }}
        >
          <Box flex="1">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Box>
          <Box width="100%" maxWidth={{ md: 550 }} marginLeft={{ md: 5 }}>
            <ContactForm defaultValue={defaultValue} heading={heading} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default FormSection;
